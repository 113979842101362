<template>
  <div class="main_right">
    <div class="Box">
      <el-table :data="orderlist" style="width: 100%;height: calc(100% - 50px);overflow-y: auto" border v-loading="loading" stripe>
        <el-table-column prop="ddbh" label="订单编号" align="center" width="200px"></el-table-column>
        <el-table-column prop="spmc" label="商品名称" align="center" width="150px"></el-table-column>
        <el-table-column prop="spsl" label="商品数量" align="center" width="80px"></el-table-column>
        <el-table-column prop="ddjg" label="订单价格" align="center" width="80px"></el-table-column>
        <el-table-column prop="zffs" label="支付方式" align="center" width="80px">
          <template slot-scope="scope">
            {{ scope.row.zffs === 1 ? '微信' : (scope.row.zffs === 2 ? '支付宝' : '无') }}
          </template>
        </el-table-column>
        <el-table-column prop="zfpzh" label="支付凭证号" align="center"></el-table-column>
        <el-table-column prop="ddzt" label="支付状态" align="center" width="80px">
          <template slot-scope="scope">
            {{ scope.row.ddzt === 1 ? '未支付' : scope.row.ddzt === 2 ? '已支付' : '已取消' }}
          </template>
        </el-table-column>
        <el-table-column prop="cjrq" label="创建日期" align="center" width="200px">
          <template slot-scope="scope">
            {{ formatDate(scope.row.cjrq) }}
          </template>
        </el-table-column>
        <el-table-column prop="zfrq" label="支付日期" align="center" width="200px">
          <template slot-scope="scope">
            {{ formatDate(scope.row.zfrq) }}
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center" width="200px">
          <template slot-scope="scope">
          <span v-if="scope.row.ddzt === 1">
            <el-button type="success" size="mini" icon="el-icon-check" @click="goToPay(scope.row)"
                       round>支付</el-button>
            <el-button icon="el-icon-close" type="primary" size="mini" @click="cancelOrder(scope.row)"
                       round>取消</el-button>
          </span>
            <span v-else-if="scope.row.ddzt === 3">
            <el-button type="danger" size="mini" @click="deleteOrder(scope.row)" icon="el-icon-delete" round>
              删除
            </el-button>
          </span>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
          class="pager"
          background
          @current-change="handleCurrentChange"
          @size-change="handleSizeChange"
          :current-page="currentPage"
          :page-sizes="[10, 20, 30, 50]"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
      ></el-pagination>
      <Payment ref="pay" @load="load"/>
    </div>
  </div>
</template>
<script>
import {orderQueryMy, orderUpdate} from '@/api/vip';
import Payment from  '@/views/vip/payment.vue' ;
export default {
  name: 'order',
  components: {Payment},
  data() {
    return {
      pageSize: 10,
      currentPage: 1,
      total: 0,
      orderlist: [],
      loading: false
    }
  },
  created() {
    this.getMyOrder();
  },
  methods: {
    load() {
      this.getMyOrder()
    },
    async getMyOrder() {
      this.loading = true
      const {result: {list, total}, resultCode} = await orderQueryMy({
        cjr: sessionStorage.getItem('yhid'),
        pageSize: this.pageSize,
        currentPage: this.currentPage,
      });
      if (resultCode === 200) {
        this.loading = false
        this.orderlist = list;
        this.total = total;
      }
    },
    handleCurrentChange(page) {
      this.currentPage = page;
      this.getMyOrder();
    },
    handleSizeChange(size) {
      this.pageSize = size
      this.getMyOrder();
    },

    goToPay(order) {
      console.log(order,)
      const song = this.$refs.pay
      song.dialogVisible = true;
      song.showPaymentMethod = true
      song.width = '480px'
      song.title = '立即支付'
      song.orderNo = order.ddbh
      song.paymentAmount = order.ddjg
      song.ddid = order.ddid
      song.loadQRCode('支付宝')
      song.startPaymentStatusPolling()
    },
    cancelOrder(order) {
      console.log(order)
      order.scbj = 0;
      order.ddzt = 3
      this.del(order)
    },
    deleteOrder(order) {
      order.scbj = 1;
      this.del(order)
    },
    async del(value) {
      const res = await orderUpdate({
        ddid: value.ddid,
        ddzt: value.ddzt,
        scbj: value.scbj
      })
      if (value.scbj === 0) {
        this.$message.success('取消成功')
      } else {
        this.$message.success('删除成功')
      }
      await this.getMyOrder();
    },
    formatDate(dateTime) {
      if (dateTime) {
        const options = {
          year: 'numeric',
          month: 'long',
          day: 'numeric',
          hour: 'numeric',
          minute: 'numeric',
          second: 'numeric',
        };
        return new Date(dateTime).toLocaleString(undefined, options);
      }
      return '';
    }
  }
}
</script>
<style lang="scss" scoped>
/deep/ .el-table th {
  background-color: #e4e7ed;
}
/deep/.el-pagination .el-select .el-input .el-input__inner{
  height: 30px;
}
.el-pagination{
  padding-top: 20px;
  text-align: right;
}
.box {
  width: 100%;
  height: 100%;
  margin: auto;

  .pager {

  }
}
.main_right {
  width: calc(100% - 10.5vw);
  height: 100%;
  float: left;
  box-sizing: border-box;
  padding: 0 2%;
  position: relative;
  .Box {
    width: 100%;
    height: 100%;
    box-shadow: 0px 0px 16px 0px rgba(89, 144, 255, 0.3);
    border-radius: 10px;
    padding: 2%;
    box-sizing: border-box;
    position: relative;
  }
}
</style>
